@font-face {
  font-family: 'Chicago';
  font-style: normal;
  font-weight: 400;
  src: url('./ChicagoFLF.ttf'); /* IE9 Compat Modes */
  src: local('Chicago'), local('Chicago'),
       url('./ChicagoFLF.ttf') format('truetype'), /* Safari, Android, iOS */
}

* {
  box-sizing: border-box;
  -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    font-smooth: never;
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: none;
}
body {
  margin: 0;
  font-family: 'Chicago', Arial, sans-serif;
  color: white;
  -webkit-font-smoothing: always;
  -moz-osx-font-smoothing: none;
  background-color: rgb(17, 17, 17);
}

p, h1, h2, h3,h4 {
  font-weight: 400;
  font-size: 12px;
}

:not(input):not(textarea) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --green-color: rgb(0, 255, 0);
  --red-color: rgb(208,48,29);
  --red-highlight-color: rgb(210, 99, 35);
}

.App {
  margin: 0 auto;
  min-width: 720px;
  max-width: 800px;
  position: relative;
}

.player {
  display:      block;
  width:        100%;
  min-width:    720px;
  aspect-ratio: auto 4 / 3;
  overflow:     hidden;
  position:     relative;
}
.player > h2 {
  color: white;
  text-shadow: 0 1px black;
  background-color: rgba(0, 0, 0, 0.288);
}
.player::after {
  content:'';
  position: absolute;
  display: block;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  border: 1px solid var(--red-color);
  z-index: 5;
}

button {
  border: 1px solid var(--red-color);
  border-color: var(--red-color);
  background-color:transparent;
  color: var(--red-color);
  min-height: 35px;
  min-width: 70px;
  cursor: pointer;
  margin-top: 3px;
  font-size: 10px;
  text-transform:uppercase;
  font-smooth: never;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
}
button+button {
  border-left: none;
}
button.active {
  background-color: var(--red-color);
  color: black;
}